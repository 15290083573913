<template>
	<div class="rootDiv">
		<div class="retrieve">
			<div class="searchDiv flex flex-jb">
				<div class="flex flex-ac flex-w">
					<div class="mr-30 flex flex-ac">
						<span class="fs-16 flex1 mr-20">系列名称：</span>
						<el-input class="w-250" v-model="retrieveForm.seriesName" clearable></el-input>
					</div>
					<div class="mr-30 flex flex-ac">
						<span class="fs-16 flex1 mr-20">是否结束：</span>
						<el-select class="w-250" v-model="retrieveForm.isGq" clearable placeholder="请选择">
							<el-option label="正执行" :value="0">
							</el-option>
							<el-option label="已结束" :value="1">
							</el-option>
						</el-select>
					</div>
					<div>
						<el-button type="primary" @click="submitSearch">查询</el-button>
					</div>
				</div>
				<div class="flex flex-ac">
					<div class="fs-16 fc-6b9eff pointer" @click="addList">
						<i class="el-icon-circle-plus-outline mr-10 fs-18"></i>新增
					</div>
					<!-- <div class="ml-40 fc-666 fs-16 pointer" @click="getArea" v-if="helpTitle == '助力活动'">助力区间</div> -->
					<!-- <div class="ml-40 fc-666 fs-16 pointer" @click="returnActivity" v-if="helpTitle == '助力区间'">返回</div> -->
				</div>
			</div>
		</div>
		<TableHeightFull class="tableDiv pall-30">
			<!-- 表格 -->
			<EleTable ref="tableRef" slot="table" :tableData="tableData" :columns="activityColumns" height="100%"
				:border="false" v-loading="tabLoading" :row-style="{ cursor: 'pointer' }">
				<template v-slot:propImg="scope">
					<img :src="scope.row.propImg" alt="" class="smallImg" v-if="scope.row.propImg">
				</template>
			</EleTable>
			<!-- 分页 -->
			<PagingComponent slot="after" :page-info="pageInfo" @getTableData="getTableData" />
		</TableHeightFull>
		<!-- 弹框 -->
		<el-dialog :title="dialogTitle" :visible.sync="dialogFormVisible" top="15vh" width="30%" @close="resetForm"
		:modal-append-to-body="false" :wrapperClosable="false" :close-on-press-escape="false">
			<el-form :model="ruleForm" ref="ruleForm" label-width="100px">
				<template>
					<el-form-item label="道具名称" prop="propName" v-if="'propName' in ruleForm"
						:rules="{required: true, message: '道具名称不能为空', trigger: 'blur'}">
						<el-input type="text" v-model="ruleForm.propName" clearable></el-input>
					</el-form-item>
					<el-form-item label="道具类型" prop="propType" v-if="'propType' in ruleForm"
						:rules="{required: true, message: '道具类型不能为空', trigger: 'blur'}">
						<el-select v-model="ruleForm.propType" placeholder="请选择类型" @change="handleChange" clearable>
							<el-option v-for="(item,index) in propTypes" :label="item.key" 
							:value="item.value">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="优惠券类型" prop="couponsType" v-if="'propType' in ruleForm && ruleForm.propType == 4"
						:rules="{required: true, message: '优惠券类型不能为空', trigger: 'blur'}">
						<el-select v-model="ruleForm.couponsType" placeholder="请选择类型" clearable>
							<el-option v-for="(item,index) in couponsTypes" :label="item.key" :value="item.value">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="数量" prop="num" v-if="'num' in ruleForm"
						:rules="{required: true, message: '道具数量不能为空', trigger: 'blur'}">
						<el-input type="number" v-model="ruleForm.num" clearable></el-input>
					</el-form-item>
					<el-form-item label="道具图片" prop="propImg" v-if="'propImg' in ruleForm"
						:rules="{required: true, message: '道具图片不能为空', trigger: 'blur'}">
						<upload-file upStyle="width: 80px; height: 80px; border-radius: 5px; overflow: hidden;"
							iconName="el-icon-upload" iconSize="16" v-model="ruleForm.propImg">
						</upload-file>
					</el-form-item>
					<el-form-item label="有效期" prop="validDay" v-if="'validDay' in ruleForm"
						:rules="{required: true, message: '有效期不能为空', trigger: 'blur'}">
						<el-input type="number" v-model="ruleForm.validDay" clearable></el-input>
					</el-form-item>
					<el-form-item label="面额" prop="money" v-if="'money' in ruleForm && ruleForm.propType == 4"
						:rules="{required: true, message: '面额不能为空', trigger: 'blur'}">
						<el-input type="number" v-model="ruleForm.money" clearable></el-input>
					</el-form-item>
					<el-form-item label="门槛" prop="doorSill" v-if="'doorSill' in ruleForm && ruleForm.propType == 4"
						:rules="{required: true, message: '门槛为空', trigger: 'blur'}">
						<el-input type="number" v-model="ruleForm.doorSill" clearable></el-input>
					</el-form-item>
					<el-form-item label="邀请人数" prop="invUsrNum" v-if="'invUsrNum' in ruleForm"
						:rules="{required: true, message: '邀请人数不能为空', trigger: 'blur'}">
						<el-input type="number" v-model="ruleForm.invUsrNum" clearable></el-input>
					</el-form-item>
					<!-- 					<el-form-item label="" prop="seriesId" v-if="'seriesId' in ruleForm"
						:rules="{required: true, message: '请至少选择一个系列', trigger: 'change'}">
						<el-select v-model="ruleForm.seriesId" placeholder="请选择"
							:disabled="dialogTitle=='新增'?false:true">
							<el-option v-for="item in seriesList" :key="item.id" :label="item.seriesName"
								:value="item.id" v-if="item.value != 1"></el-option>
						</el-select>
					</el-form-item> -->
				</template>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="dialogFormVisible = false">取 消</el-button>
				<el-button type="primary" @click="affirmPopup" :loading="butLoading">确 定</el-button>
			</div>
		</el-dialog>
	</div>
</template>
<script>
	import indexMixin from "@/utils/indexMixin"
	import axios from "axios"
	import {
		mapState
	} from 'vuex'
	export default {
		name: 'helpActivity',
		mixins: [indexMixin],
		components: {
			"UploadFile": (resolve) => require(["@/components/UploadFile"], resolve),
			"EleTable": (resolve) => require(["@/components/public/TSimpleTable"], resolve),
			"TableHeightFull": (resolve) => require(["@/components/public/TableHeightFull"], resolve),
			"PagingComponent": (resolve) => require(["@/components/public/PagingComponent"], resolve),
		},
		computed: {
			...mapState('menu', {
				'propTypes': state => state.dictList ? state.dictList.prop_type : [], //道具类型
				'couponsTypes': state => state.dictList ? state.dictList.coupons_type : [], //优惠劵类型
			})
		},
		data() {
			return {
				helpTitle: '助力活动',
				iscouponsTypes: false,//是否是优惠券类型
				retrieveForm: {
					seriesName: '',
					isGq: '',
				},
				tabLoading: false,
				butLoading: false,
				//活动列表
				activityColumns: [{
						type: 'index',
						label: '序号',
						// width: '60',
						align: 'center',
						index: this.indexMethod
					}, {
						label: '道具名称',
						prop: 'propName',
						align: 'left',
						'show-overflow-tooltip': true
					}, {
						label: '道具类型',
						prop: 'propType',
						align: 'left',
						'show-overflow-tooltip': true
					}, {
						columnType: 'custom',
						label: '道具图片',
						prop: 'propImg',
						align: 'left',
						'show-overflow-tooltip': true
					}, {
						label: '门槛',
						// width:'80',
						prop: 'doorSill',
						align: 'left',
						'show-overflow-tooltip': true
					}, {
						label: '优惠券类型',
						// width:'80',
						prop: 'couponsType',
						align: 'left',
						'show-overflow-tooltip': true
					},
					// {
					//      columnType: 'fun',
					//      label: '关联系列',
					//      prop: 'seriesName',
					//      align: 'left',
					//      'show-overflow-tooltip': true,
					//      formatData: (e) => {
					//        let ele = this.seriesList.find(ele => ele.id == e)
					//        return ele ? ele.seriesName : e
					//      }
					//    }, 
					{
						label: '邀请人数',
						prop: 'invUsrNum',
						align: 'left',
						'show-overflow-tooltip': true
					}, {
						columnType: 'button',
						label: '操作',
						align: 'right',
						fixed: 'right',
						width: '150',
						buttons: [{
							type: 'text',
							text: '编辑',
							fn: this.editList,
						}, {
							type: 'text',
							text: '删除',
							fn: this.deleteList,
						}, ],
					},
				],
				tableData: [],
				//弹框
				dialogTitle: '新增',
				dialogFormVisible: false,
				ruleForm: {
					//助力活动
					propName: '', //道具名称
					propType: '', //道具类型
					propImg: '', //道具图片
					validDay: '', //有效期
					num: '', //数量
					money: '', //金额
					doorSill: '', //门槛
					invUsrNum: '', //邀请人数
					couponsType: '', //优惠券类型
				},
				seriesList: [], //系列列表
			}
		},
		mounted() {
			// this.helpTitle = '助力活动'
			this.getTableData()
			// this.findBoxSeries()
			// this.testSave()
		},
		methods: {
			//搜索
			submitSearch() {
				this.getTableData()
			},
			handleChange(val){
				console.log(val,"val")
				if(val == 4){
					this.iscouponsTypes = true
				}else{
					this.iscouponsTypes = false
				}
			},
			//获取表格数据
			getTableData() {
				this.$http.get('/helpProp/findHelpPropList', {
					params: {
						currentPage: this.pageInfo.pageNum,
						pageSize: this.pageInfo.pageSize,
					}
				}).then(({
					data: result
				}) => {
					this.tabLoading = false
					this.tableData = result.data.list
					this.pageInfo.total = result.data.total
				}).catch((err) => {})
			},
			//删除
			deleteList(row) {
				this.$confirm('您确认删除？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(_ => {
					this.$http.post('/helpProp/removeHelpPropById', {
						id: row.id
					}).then(({
						data: result
					}) => {
						this.getTableData()
						this.$message({
							message: '删除成功',
							type: 'success'
						})
					})
				}).catch(_ => {})
			},
			//新增列表
			addList() {
				this.dialogTitle = '新增'
				this.ruleForm = {
						//助力活动
						propName: '', //道具名称
						propType: '', //道具类型
						validDay: '', //有效期
						num: '', //数量
						propImg: '', //道具图片
						money: '', //金额
						doorSill: '', //门槛
						invUsrNum: '', //邀请人数
						couponsType: '', //优惠券类型
					},
					this.dialogFormVisible = true
			},
			//跳转详情
			editList(row) {
				this.dialogFormVisible = true
				this.dialogTitle = '编辑'
				this.findHelpPropById(row.id)
			},
			//获取详情数据
			findHelpPropById(id) {
				this.$http.get(`/helpProp/findHelpPropById?id=${id}`).then(({
					data: res
				}) => {
					let propData = res.data
					// if (goodData.detailsList == null || goodData.detailsList == undefined || goodData
					// 	.detailsList == "") {
					// 	goodData.detailsList = []
					// }
					this.ruleForm = propData
				})
			},
			//确认弹框
			affirmPopup() {
				this.$refs['ruleForm'].validate((valid) => {
					if (valid) {
						this.butLoading = true
						let apiUrl = ''
						if (this.dialogTitle == '新增') {
							apiUrl = '/helpProp/addHelpProp'
						} else if (this.dialogTitle == '编辑') {
							apiUrl = '/helpProp/editHelpProp'
						}
						this.$http.post(apiUrl, this.ruleForm).then(({
							data: result
						}) => {
							this.$message({
								message: this.dialogTitle + '成功',
								type: 'success'
							})
							this.butLoading = false
							this.dialogFormVisible = false
							this.getTableData()
						}).catch(err => {
							this.butLoading = false
						})
			  }
				})
			},
			//重置清除校验
			resetForm() {
				Object.assign(this.$data.ruleForm, this.$options.data().ruleForm)
				this.$nextTick(() => {
			  this.$refs.ruleForm.clearValidate()
				})
			},
		},
	}
</script>
<style lang="less" scoped>
</style>
